import React from "react";
import "./PersonCard.css";


function PersonCard(props) {
    return(
        <div className="card">
            {props.member} as...
            <img src={props.image} alt={props.member}/>
            {props.as}
        </div>
    )
}

export default PersonCard;