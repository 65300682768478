import React from "react";
import './Footer.css'

const LOGO_DATA = [
    {name: "youtube", image: require("./../../assets/logos/youtube.png"), link: "https://www.youtube.com/watch?v=t_CtLZM1f0U&list=UUbkvKjXu9hOCo_KklDBjUgg"},
    {name: "facebook", image: require("./../../assets/logos/facebook.png"), link: "https://www.facebook.com/guns4roses"},
    {name: "instagram", image: require("./../../assets/logos/instagram.png"), link: "https://www.instagram.com/guns4rosesband"},
    {name: "twitter", image: require("./../../assets/logos/twitter.png"), link: "http://www.twitter.com/guns4roses"},
]


function Footer() {
    const year = new Date().getFullYear()

    return (
        <div className="footer-container">
            <div className="copyright">
                <div className="logo-container">
                    {
                        LOGO_DATA.map((data, index) => (
                            <a key={index} href={data.link} target="_blank" rel="noopener noreferrer">
                                <img src={data.image} alt={data.name}/>
                            </a>
                        ))
                    }
                </div>
                &copy; {year} GUNS 4 ROSES
            </div>
        </div>
    )
}

export default Footer;