import React from "react";
import "./Press.css";


const REVIEW_DATA = [
    {author: "Joshua Heller, Talent Buyer, The Lodge At Deadwood Casino, Deadwood, SD", review: "Words can't even begin to describe how good these guys were. Guns 4 Roses is one of the most professional bands I have had the pleasure to work with. There is no question, we will be hiring them again. They are worth every penny! Thank you Guns 4 Roses for an awesome show and, a great experience!"},
    {author: "Ben McKee, Classic Rock 104.5 Program Director, San Antonio, TX", review: "It was Sunset Strip for one night in Corpus. When Guns 4 Roses came and kicked our city's a$$. G4R they sound, look, and party like the original.... Don't miss them rockin' your city. [...] Best Radio Show We've Ever Had!"},
    {author: "Jill Jackson, House of Blues Concert Review, Harder Beat Magazine", review: "Jason Ferchaud does a great job posing as frontman Axl Rose, thrilling females in the crowd with his dance moves as well as a clear, strong deliverance of the lyrics"},
    {author: "Steven Dollar/April Kelly, Guns 4 Roses brings the 'Jungle' back to Monroe, Monroe News Star - Monroe, LA", review: "hey've got the look, the sound, everything is just like Guns N Roses"},
    {author: "Julio Gutierrez, TRECE MIL PERSONAS DISFRUTARON A LAS BANDAS, Expreso - Hermosillo, MX", review: "Welcome To The Jungle desato la locura, a cargo de los musicos, quienes sorprendieron con su parecido a los mismisimos Axl Rose y el guitarrista Slash."}
];


function Press() {
  return (
    <div className="press-container">
        <h2 className='logo-color1'>Press Reviews</h2>
        <div className="press-review-container">
            {REVIEW_DATA.map((data, index) => (
                <div className={`review-container ${index % 2 === 0 ? "even" : "odd"}`} key={index}>
                    <div className="review-container-content">
                        <div className="author"> {data.author} </div>
                        <div className="review"> &ldquo;{data.review}&rdquo; </div>
                    </div>
                </div>
            ))}
        </div>

        <h2 className='logo-color1'>Press Contact</h2>
        <p className="contact-info">If you would like to feature Guns 4 Roses in your publication, podcast, radio, or television program, please contact Chris at <span>214-552-9674</span> or email the band through our <a href='/Booking'>Booking</a> page.</p>
    </div>
  );
}

export default Press;
