import React from 'react';
import './PhotoCard.css'

function PhotoCard(props) {
    return(
        <img
            src={props.image}
            alt={props.title}
            className="slideshow-image slide"
        />
    )
}

export default PhotoCard;