import React from 'react';
import './Home.css';

import Slideshow from './../../components/Slideshow/Slideshow'
import MeetTheBand from '../../components/MeetTheBand/MeetTheBand';

const LOGO = require('./../../assets/guns4roses.png'),
      WGTB_LOGO = require('./../../assets/worldsgreatesttributebands_logo.png');

function Home() {
  return (
    <div className="home-container">
        <div className='row'>
            <img src={LOGO} alt='Guns 4 Roses'/>
            <h1>
                The Ultimate <br/> 
                <div className='title-emphasis'> 
                    <span className='logo-color1'>GUNS</span>
                    <span className='logo-color2'> N' </span>
                    <span className='logo-color1'>ROSES</span>
                </div>
                Tribute Experience
            </h1>
        </div>

        <div className='row'>
            <Slideshow/>
        </div>

        <div className='row'>
            <div className='description'>
                <p>
                    The total <span className='emphasis'>Guns N' Roses</span> rock and roll experience; long hair, leather 
                    pants, sunglasses, bandanas, top hats, and Les Pauls!
                </p>
                <p>
                    Guns 4 Roses rock all your favorite Guns N' Roses songs from <span className='emphasis'>Welcome to the Jungle</span> to <span className='emphasis'>November Rain</span> and everything in between putting on a full stage 
                    production bringing you back to the classic <span className='emphasis'>Appetite for Destruction</span> GNR lineup. More than just a Guns N' Roses 
                    tribute band, Guns 4 Roses has been selected as the <span className='emphasis'>World's Greatest Guns N' Roses Tribute Band</span> by AXS TV!
                </p>
                <img className='sticker' id="worldsgreatesttributebands" src={WGTB_LOGO} alt='Worlds Greatest Tribute Bands logo'></img>
            </div>
        </div>

        <div className='row' id="MeetTheBand">
            <MeetTheBand/>
        </div>

        <div className='spotlight-container'>
            <div className='spotlight blue' id="spotlight1"/>
            <div className='spotlight blue' id="spotlight2"/>
            <div className='spotlight yellow' id="spotlight3"/>
            <div className='spotlight pink' id="spotlight4"/>
        </div>
    </div>
  );
}

export default Home;
