import React from 'react';
import './Sidebar.css';


const Sidebar = (isActive, menu) => {
    return(
        <div className={`sidebar ${isActive ? 'active' : ''}`}>
            <div className="logo">
                <span className='logo-color1'>GUNS</span>
                <span className='logo-color2'> 4 </span> 
                <span className='logo-color1'>ROSES</span>
            </div>
            {menu}
        </div>
    );
};

export default Sidebar;