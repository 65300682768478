import React from 'react';
import './ThankYouModal.css';


const ThankYouModal = ({ isOpen, onClose }) => {
    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <p className='title'>Thank you!</p> 
                        <p className='caption'>We will reach out to you shortly.</p>
                        <span className="close-btn" onClick={onClose}>
                            &times;
                        </span>
                    </div>
                </div>
            )}
        </>
    );
};

export default ThankYouModal;
