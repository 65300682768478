import React from "react";
import "./MeetTheBand.css";
import PersonCard from "./PersonCard/PersonCard";

const CARD_DATA = [
    {image: require("./../../assets/meettheband/izzy.png"), member: "David", as: "Izzy"},
    {image: require("./../../assets/meettheband/adler.png"), member: "Martin", as: "Adler"},
    {image: require("./../../assets/meettheband/axl.png"), member: "Colby", as: "Axl"},
    {image: require("./../../assets/meettheband/duff.png"), member: "Bender", as: "Duff"},
    {image: require("./../../assets/meettheband/slash.png"), member: "Eamonn", as: "Slash"},
]

function MeetTheBand() {

    return (
        <div className="meettheband-container">
            <h2 className='logo-color1'>Meet the Band</h2>
            <div className="card-container">
                {
                    CARD_DATA.map((data, index) => (
                        <PersonCard image={data.image} member={data.member} as={data.as} key={index}/>
                    ))
                }
            </div>

            <div className="description">
                <p>The members of Guns 4 Roses have performed on stages all across the United States, Canada, Mexico, Central America and South America sharing stages with artists such as Steven Adler of GNR, Vince Neil of Motley Crue and Bret Michaels of Poison while playing for crowds of Guns N Roses fans as large as 26,000. They've also had the pleasure of personally meeting many of the members of their famous muses, including Slash, Duff McKagan, Dizzy Reed, Matt Sorum, Gilby Clarke, and more.</p>
            </div>
        </div>
    );
}

export default MeetTheBand;