import React from "react";
import './Booking.css'

import ContactForm from "./ContactForm/ContactForm"


const bookingAgentName = process.env.BOOKING_AGENT_NAME || "Chris Bender";
const bookingAgentEmail = process.env.BOOKING_AGENT_EMAIL || "chris@guns4roses.com";

const LINK_DATA = [
    {name: "Email", link: "mailto:" + bookingAgentEmail, image: require("./../../assets/logos/email.png")},
    {name: "The Bash", link: "https://www.thebash.com/gunsnrosestributeband/guns4roses", image: require("./../../assets/logos/thebash.png")},
    {name: "Gig Salad", link: "http://www.gigsalad.com/guns4roses", image: require("./../../assets/logos/gigsalad.png")}
]


function Booking() {
    return(
        <div className="booking-container">
            <h2 className="logo-color1">Booking</h2>
            <div className="booking-container-row">
                <div className="booking-container-division">
                    <h2 className="logo-color1">Many Ways to Book</h2>
                    <p>For booking inquiries please contact <span className="emphasis">{bookingAgentName}</span> at <a href={"mailto: " + bookingAgentEmail}>{bookingAgentEmail}</a>.</p>
                    
                    <div className="booking-button-container">
                        {
                            LINK_DATA.map((data, index) => (
                                <a key={index} className="booking-button" href={data.link} target="_blank" rel="noopener noreferrer">
                                    <img src={data.image} alt={data.name}/>
                                </a>
                            ))
                        }
                    </div>
                </div>
                <div className="booking-container-division">
                    <h2 className="logo-color1">Or Fill Out This Contact Form</h2>
                    <ContactForm/>
                </div>
            </div>
        </div>
    )
}

export default Booking;