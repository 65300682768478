import React, { useState, useEffect } from "react";
import "./ShowEntry.css"

function convertToISOFormat(dateString) {
    // Assuming the input format is 'MM-DD-YYYY'
    const [month, day, year] = dateString.split('-');
    const isoDateString = `${year}-${month}-${day}`;
    return isoDateString;
}


function ShowEntry(props) {
    const isLinkEmpty = !props.link || props.link.trim() === "";
    const isoDate = new Date(convertToISOFormat(props.date));
    const dateString = new Date(isoDate).toLocaleDateString('en-US', {
        timeZone: 'UTC',
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
    

    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function GetInfoButton() {
        return (
            <button className="info-button" onClick={() => window.open(props.link, '_blank', 'noopener noreferrer')} disabled={isLinkEmpty}>
                {isLinkEmpty ? "Not Available" : "More Info"}
            </button> 
        )
    }

    function GetLargeWindowRow() {
        return (
            <tr className="show-entry">
                <td className="date">{dateString}</td>
                <td className="venue">{props.venue}</td>
                <td className="location">{props.location}</td>
                <td className="info">{GetInfoButton()}</td>
            </tr>
        );
    }

    function GetMediumWindowRow() {
        return (
            <tr className="show-entry">
                <td className="date">{dateString}</td>
                <td className="extended-location">
                    <span className="location">{props.location}</span>
                    <span className="venue">{props.venue}</span>
                </td>
                <td className="info">{GetInfoButton()}</td>
            </tr>
        );
    }

    function GetSmallWindowRow() {
        return (
            <tr className="show-entry">
                <td className="extended-location">
                    <span className="date">{dateString}</span>
                    <span className="location">{props.location}</span>
                    <span className="venue">{props.venue}</span>
                </td>
                <td className="info">{GetInfoButton()}</td>
            </tr>
        );
    }

    function GetShowEntry() {
        if (window.innerWidth > 1100) {
            return GetLargeWindowRow();
        } else if (window.innerWidth > 850) {
            return GetMediumWindowRow();
        } 
        return GetSmallWindowRow();
    }

    return(GetShowEntry());
}

export default ShowEntry;