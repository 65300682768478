import React, { useState, useEffect} from 'react';
import './Navbar.css';

import Sidebar from './Sidebar/Sidebar';


const Navbar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [color, setColor] = useState(false);

    /* the menu is toggled when */
    const toggleMenu = () => {
        setTimeout(() => {
            setMenuOpen(!isMenuOpen);
        }, 0);
    };

    /* navigation bar turns black when you scroll down */
    useEffect(() => {
        const updateNavbarColor = () => {
            setColor(window.scrollY >= 90)
        }

        window.addEventListener('scroll', updateNavbarColor);

        return () => {
          window.removeEventListener('scroll', updateNavbarColor);
        };
      }, []);

    /* sidebar disappears when the user clicks outside of it */
    useEffect(() => {
        const closeSidebar = (event) => {
            if (isMenuOpen && !event.target.closest('.sidebar') 
                           && !event.target.closest('.hamburger-menu')) {
                setMenuOpen(false);
            }
            else if (isMenuOpen && !event.target.closest('.menu-item')) {
                setMenuOpen(false);
            }
            else if (isMenuOpen && event.target.closest('.scroll-item')) {
                setMenuOpen(false);
            }
        };

        document.addEventListener('click', closeSidebar);

        return () => {
            document.removeEventListener('click', closeSidebar);
        };
    }, [isMenuOpen]);

    const FetchMenu = () => {
        return(
            <div className={'menu'}>
                <a className='menu-item' href="/">Home</a>
                <a className='menu-item scroll-item' href="/#MeetTheBand">About</a>
                <a className='menu-item' href="/Shows">Shows</a>
                <a className='menu-item' href="/Media">Media</a>
                <a className='menu-item' href="/Press">Press</a>
                <a className='menu-item' href="/Booking">Booking</a>
            </div>
        )
    }

    const RenderSidebar = () => {
        return (   
            Sidebar(isMenuOpen, FetchMenu())
        )
    }

    return(
        <nav className={`navbar ${color ? 'bg-active' : ''}`}>
            <a className="logo" href="/">
                <span className='logo-color1'>GUNS</span>
                <span className='logo-color2'> 4 </span>
                <span className='logo-color1'>ROSES</span>
            </a>
            <div className={'desktop-menu'}>
                {FetchMenu()}
            </div>
            <div className="hamburger-menu" onClick={toggleMenu}>
                &#9776; {/* Hamburger icon */}
            </div>
            {RenderSidebar()}
        </nav>
    );
};

export default Navbar;