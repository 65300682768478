import React from 'react'
import './PhotoCard.css'


function PhotoCard(props) {
    return(
        <div className='photocard-container'>
            <img className='photocard' src={props.image} alt={props.caption} />
            <div className='infocard'>
                {props.copyright ?
                    <div className='copyright'>&copy;{props.copyright}</div>
                    :
                    <></>
                }
            </div>
        </div>
    )
}

export default PhotoCard