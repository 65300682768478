import React from 'react';
import './Slideshow.css';

import PhotoCard from './PhotoCard/PhotoCard'


const GALLERY_DATA = [
  { image: require("./../../assets/carousel/carousel1.png"), title: "1"},
  { image: require("./../../assets/carousel/carousel2.png"), title: "2"},
  { image: require("./../../assets/carousel/carousel3.png"), title: "3"},
  { image: require("./../../assets/carousel/carousel4.png"), title: "4"},
  { image: require("./../../assets/carousel/carousel5.png"), title: "5"},
  { image: require("./../../assets/carousel/carousel6.png"), title: "6"},
  { image: require("./../../assets/carousel/carousel7.png"), title: "7"},
  { image: require("./../../assets/carousel/carousel8.png"), title: "8"},
];


function Slideshow() {
  const numImagesInSequence = GALLERY_DATA.length;
  const numImagesFitInScreen = Math.ceil(window.innerWidth / 150);
  const numSequencesFitInScreen = 2 * Math.ceil(numImagesFitInScreen / numImagesInSequence);

  // Repeat the image sequence to fit the screen
  const repeat = (arr, n) => [].concat(...Array(n).fill(arr));
  const galleryDataCopies = repeat(GALLERY_DATA, numSequencesFitInScreen);

  return (
    <div className="slideshow-container">
      <div className="slide-track">
        {galleryDataCopies.map((data, index) => (
            <PhotoCard image={data.image} title={data.title} key={index}/>
        ))}
      </div>
    </div>
  );
}

export default Slideshow;