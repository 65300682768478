import React from 'react'
import './VideoCard.css'


function VideoCard(props) {
    return(
        <div className='videocard'>
            <iframe src={props.link} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            <div className='videocard-background'/>
            <div className='videocard-description-container'>
                <div className='title'>{props.caption}</div>
                <div className='description'>{props.description}</div>
            </div>
        </div>
    )
}

export default VideoCard