import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './views/Home/Home';
import Press from './views/Press/Press';
import Media from './views/Media/Media';
import NotFound from './views/NotFound/NotFound';
import Shows from './views/Shows/Shows';
import Booking from './views/Booking/Booking';


function App() {
    return (
        <Router>
            <div className="App">
                <Navbar/>
                <Routes>
                    <Route exact path="/" element={<Home/>} />   
                    <Route path="/Press" element={<Press />} />
                    <Route path="/Media" element={<Media />} />
                    <Route path="/Shows" element={<Shows />} />
                    <Route path="/Booking" element={<Booking />} />
                    {/* Add other routes here */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
