import React from "react";
import "./NotFound.css"


function NotFound() {
    return (
        <div className="notfound-container">
            <h1>Error <span className="logo-color2">4</span>
                      <span className="logo-color1">0</span>
                      <span className="logo-color2">4</span>
            </h1>
            <h3>Page Not Found.</h3>
        </div>
    )
}

export default NotFound;